@tailwind base;
@tailwind components;
@tailwind utilities;
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background:grey; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: black; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 0.1px solid black; /* creates padding around scroll thumb */
}
.react-pdf__Page__textContent  {
 background: transparent !important;
 display: none !important;
}
/* This will ensure the default cursor is used for all elements */
body {
  cursor: default; /* Use default cursor */
}

/* You can customize specific elements if needed */
.some-class {
  cursor: pointer; /* For clickable elements */
}
/* Add this to your CSS file (e.g., App.css or index.css) */
/* Add this to your CSS file (e.g., App.css or index.css) */
/* Add this to your global CSS file (index.css or App.css) */
@keyframes coinTurn {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.hover\:coin-turn:hover {
  animation: coinTurn 2s ease-in-out;
}
